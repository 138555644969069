
export const Title = "The Zahar Wiki";

export const BaseApiUrl = "https://api.zahar.wiki";

export const GoogleMapsApiKey = "AIzaSyAAOP-zoNlAYcViJlmN3opnPv8B9uNtVEs";

export const AwsCognito = {
    "REGION" : "us-west-1",
    "USER_POOL_ID": "eu-west-1_ZtZgiVcFv",
    "USER_POOL_APP_CLIENT_ID": "2qbc3eg5vrg9ag7u692u2g1evq"
}
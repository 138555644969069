import { React } from "react";
import { SessionCheck } from "components/auth_check";


export default function Main() {
  return (
    <>
      <main>
        <SessionCheck onLoggedIn={() => window.location = "/dashboard"} onLoggedOut={() => window.location = "/login"}/>
      </main>
    </>
  );
}

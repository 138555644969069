import { styled } from '@mui/system';
import { Typography } from "@mui/material";

export const TitleTypography = styled(Typography)((props) => ({
  padding: 8,
  WebkitTextStroke: "2px black",
  color: "white",
  fontFamily: "Chalkduster, fantasy",
  fontSize: "8rem",
  lineHeight: 2,
  whiteSpace: "pre-wrap",
}));

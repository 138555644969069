import { React } from "react";
import { Amplify, Auth } from "aws-amplify";
import { AwsCognito } from "app_config";

Amplify.configure({
  Auth: {
    region: AwsCognito.REGION,
    userPoolId: AwsCognito.USER_POOL_ID,
    userPoolWebClientId: AwsCognito.USER_POOL_APP_CLIENT_ID,
  },
});

/**
 * @constructor
 * @param {function} onLoggedIn - Callback function
 * @param {function} onLoggedOut - Callback function
 */
export function SessionCheck(props) {
  const onLoggedIn = props.onLoggedIn || function(){};
  const onLoggedOut = props.onLoggedOut || function(){};
  const checkSession = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      onLoggedIn();
    } catch (err) {
        console.log("Invalid session");
        onLoggedOut();
    }
  };
  checkSession();
  return <></>;
}

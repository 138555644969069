import { React } from "react";

import { TitleTypography } from "components/typographies";

export default function Main() {
  return (
    <>
      <main>
        <TitleTypography
          paragaph="true"
          align="center"
          fontSize="8rem"
          lineHeight="2"
          gutterBottom
        >
          Not Found
        </TitleTypography>
      </main>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "app_router";
import { CssBaseline } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import "index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <AppBar position="static" sx={{ background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(100, 100, 100, 100))'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <i className="fa-solid fa-book-bible" style={{color: 'black', paddingLeft: '5%'}}></i>
          <Typography
            variant="h6"
            sx={{
              paddingLeft: '10px',
              fontFamily: 'Fantasy',
              fontWeight: 200,
              letterSpacing: '.5rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            The Zahar Wiki
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
    <AppRouter />
  </React.StrictMode>
);

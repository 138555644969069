import React, { lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {default as Index} from "routes/_index";
import {default as NotFound} from "routes/_not_found";
import { Title } from "app_config";

const LazyComponent = lazy(() => import(`routes${window.location.pathname}`).catch(() => {return { default: NotFound }}));

export default function AppRouter() {
  useEffect(() => {
    document.title = Title;
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index/>} />
        <Route path="*" element={<LazyComponent/>} />
      </Routes>
    </BrowserRouter>
  );
}
